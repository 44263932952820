@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

// Media Query Mixins

@mixin devices($breakpoint) {
  @if $breakpoint == xxl {
    @media only screen and (max-width: 1400px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }
}

body {
  color: #1e3542;
  padding: 0 !important;
  font-family: "Inter", sans-serif !important;
  overflow-y: auto !important;
}

a:hover {
  text-decoration: none !important;
}

.form-control {
  font-size: 14px;
  color: #758283;
  border-radius: 0;
  border-radius: 20px;
  padding: 8px 20px;

  &:focus {
    box-shadow: none !important;
    outline: none;
    border: 1px solid #a51e22 !important;
  }
}

.btn {
  font-weight: 500;
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 30px;
  font-weight: 500;
  font-family: "Inter", sans-serif;

  &:focus,
  &:active,
  &:hover {
    box-shadow: none;
    outline: none;
  }
}

.btn-danger {
  color: #fff;
  border: 1px solid #a51e22;
  background: #a51e22;
  font-weight: 400;

  &:hover {
    border: 1px solid #1f3543;
    background: #1f3543;
  }
}

.btn-outline-danger {
  color: #a51e22;
  background: #fff;
  border: 2px solid #a51e22 !important;

  &:hover {
    color: #fff;
    border: 1px solid #a51e22;
    background: #a51e22;
  }
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

[class^="bi-"]::before,
[class*=" bi-"]::before {
  font-size: 16px;
}

.form-label {
  color: #1e3542;
  font-size: 17px;
  height: 18px;
  font-weight: 500;
  margin-bottom: 2px;
}

// Header

header {
  .navbar {
    background: #fff !important;
    border-bottom: 2px solid #e2e2e2;
    padding: 15px 30px;

    .nav-link {
      color: #1e3542;
    }

    @include devices(lg) {
      padding-left: 0;
    }

    @include devices(md) {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 999;
    }
  }
}

.main-heading {
  color: #24305e;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  font-family: Gotham-Bold;

  @include devices(md) {
    font-size: 28px;
  }
}

#wrapper {
  transition: all 0.5s ease;
  position: relative;
}

.sidebar {
  .offcanvas-start {
    width: 270px;
    border: 0;
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.2);

    &.show {
      @include devices(lg) {
        visibility: hidden !important;
      }
    }

    .offcanvas-body {
      padding: 0;
    }

    ul {
      li {
        a {
          color: #676767;
          font-size: 16px;
          font-weight: 400;
          display: block;
          padding: 6px 10px;

          &:hover {
            color: #a51e22;
          }

          img {
            margin-right: 14px;
          }
        }

        a.active {
          background: #fbf0f0;
          border-right: 5px solid #a51e22;

          a {
            color: #a51e22;
          }
        }
      }
    }
  }
}
.sidebar-show {
  visibility: visible !important;
  transform: none;
}
.sidebar-hidden {
  visibility: hidden !important;
  transform: transform 0.3s ease-in-out;
}

.btn-danger-light {
  width: 42px;
  height: 42px;
  display: inline-block !important;
  line-height: 48px !important;
  background: #fdeff1 !important;
  color: #a51e22 !important;
  border-radius: 50% !important;
  border: 0 !important;
  padding: 0 !important;
  text-align: center;
  margin: 10px 20px 20px;

  span {
    &:before {
      font-size: 24px;
    }
  }
}

.offcanvas-backdrop.show {
  display: none;
}

@media (min-width: 992px) {
  #wrapper {
    padding: 0px 0 100px 270px;
    transition: 0.2s all;
  }

  .mystyle {
    padding-left: 0 !important;
    transition: 0.2s all !important;
  }

  .mystyle header .navbar {
    padding-left: 30px;
    transition: 0.2s all;
  }
}

main {
  @include devices(lg) {
    padding-top: 65px;
  }
}

.listing-section {
  padding: 40px 20px 30px;

  @include devices(lg) {
    padding: 30px 0 60px;
  }

  h2 {
    color: #1e3542;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    color: #758283;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    a {
      color: #a51e22;
      text-decoration-line: underline;
    }
  }

  .btn {
    width: 160px;

    @include devices(lg) {
      width: auto;
    }
  }

  .form-control {
    border: 0;
    padding: 5px 0;
    border-bottom: 1px solid #cecece;
    border-radius: 0;
    font-size: 17px;

    &:focus {
      border: 0 !important;
      border-bottom: 1px solid #a51e22 !important;
    }
  }
}

.search-filter {
  .bi-search {
    position: absolute;
    right: 0;
    bottom: 10px;
  }

  .bi-close {
    position: absolute;
    right: 28px;
    bottom: 12px;
    width: 15px;
  }
}

// Table

table {
  @include devices(lg) {
    width: 1200px !important;
  }

  thead {
    th {
      color: #1e3542;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      background-color: #ccc !important;
      border-bottom-width: 0 !important;
      vertical-align: middle !important;
      padding: 12px 15px !important;
    }
  }

  tbody {
    border-top: 0 !important;

    td {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #758283;
      vertical-align: middle !important;
      padding: 15px 15px !important;

      .text-danger {
        color: #a51e22 !important;
        text-decoration-line: underline;
      }
    }
  }
}

.form-switch {
  .form-check-input {
    transform: scale(1.5);
    cursor: pointer;
  }
}

.form-check-input:checked {
  background-color: #a51e22;
  border-color: #a51e22;
}

// Footer

footer {
  background: #1f3543;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  padding: 10px 20px;

  @include devices(lg) {
    // position: relative;
    padding: 20px 0;
    z-index: 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include devices(lg) {
      justify-content: center;
    }

    li {
      padding: 5px 15px;

      &:first-child {
        padding-left: 0;
      }
      a {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        text-decoration-line: underline;

        @include devices(lg) {
          font-weight: 500;
          font-size: 14px;
        }

        &:hover {
          color: #a51e22;
        }
      }
    }
  }

  .social-icon {
    li {
      padding: 0 10px;

      &:last-child {
        padding-right: 0;
      }
      a {
        text-decoration: none;
      }
    }
  }
}

// Modal

.modal {
  padding-bottom: 70px;

  .modal-content {
    border: 0;
    border-radius: 20px;
  }

  .modal-body {
    padding: 40px;

    h3 {
      color: #1e3542;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.66px;
      margin-bottom: 15px;
    }

    p {
      color: #758283;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 30px;

      a {
        color: #a51e22;
        text-decoration-line: underline;
      }
      u {
        color: #a51e22;
        cursor: pointer;
      }
    }

    .mt-250 {
      margin-top: 250px;
    }

    hr {
      border-top: 1px solid #d9d8d8;
    }

    textarea {
      height: 155px;
      resize: none;
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog.modal-lg {
    max-width: 880px;
  }

  .modal-dialog.modal-sm {
    max-width: 550px;
  }
}

.login-section {
  padding: 50px 0;
  height: 100vh;
  display: flex;
  align-items: center;

  .card {
    border-radius: 8px;
    box-shadow: 0 0 10px #00000021;
    border: 0;
    max-width: 420px;
    margin: 0 auto;

    .profile-img-card {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 20px;
      border: 2px solid #a51e22;
    }

    label {
      display: block;
      text-align: left;
      margin-bottom: 3px;
    }

    .card-body {
      padding: 40px 30px;
    }
  }
}

.signup-form {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 175px);
  padding: 30px 0;
  .card {
    max-width: 450px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 8px;

    .profile-img-card {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 0 auto 20px;
      border: 2px solid #a51e22;
    }

    label {
      display: block;
      text-align: left;
      margin-bottom: 3px;
    }
  }
}

// Switch

.cswitch {
  position: relative;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.cswitch-label {
  margin-left: 0.5rem;
  color: #0f1f4d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cswitch-input {
  position: absolute;
  opacity: 0;
  display: none;
  width: 0;
  height: 0;
  top: -100rem;
  left: -100rem;
}
.cswitch-trigger {
  position: relative;
  height: 1.8rem;
  width: 3rem;
  border-radius: 5rem;
  transition: all 0.3s ease-in;
  background-color: #78788029;
}
.cswitch-trigger::after {
  content: "";
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  background: #fff;
  left: 0.9rem;
  top: 50%;
  transform: translate(-50%, -52%);
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  border-radius: 100%;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15),
    0 0 0 1px rgba(34, 36, 38, 0.15) inset;
}
.cswitch:hover .cswitch-trigger {
  background-color: rgba(15, 31, 77, 0.2);
}

.cswitch input:checked ~ .cswitch-trigger {
  background-color: #0cc513;
}
.cswitch input:checked ~ .cswitch-trigger::after {
  left: calc(100% - 0.9rem);
}
